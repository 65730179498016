<template>
  <div class="lottery-trend-number">
    <div class="header">
      <div class="title-box">
        <p class="title">号码走势</p>
        <intro-default cont=""></intro-default>
      </div>
      <div class="search-box">
        <div class="switch_fn_bt" @click="rlt_show">{{ is_rlt ? '隐藏' : '展开' }}结果</div>
        <select-user type="1" @change="page_change"></select-user>
      </div>
    </div>
    <div class="lz_cont">
      <ul class="switch">
        <li :class="{ active: indexForSelectRank === i }" v-for="(name, i) in type_list" :key="name" @click="type_fn(i)">
          {{ name }}</li>
      </ul>
      <div class="table" id="table" ref="table">
        <table cellpadding="0" cellspacing="0" class="table-data" width="100%" ref="table">
          <thead>
            <tr>
              <th :width="getKjW()" rowspan="2" colspan="1">开奖时间
              </th>
              <th :width="getQsW()" rowspan="2" colspan="1">期号
              </th>
              <th v-if="is_rlt" :width="getOpenNumberW()" rowspan="2" colspan="1">开奖号码
              </th>
              <th v-for="name in rankNames" :key="new Date().getTime() + Math.random() * 10 + '' + name"
                :colspan="numberCount" :width="th_w * numberCount" height="40" rowspan="1">{{ name }}
              </th>
            </tr>
            <tr>
              <template v-for="n in rankNames.length">
                <th v-for="(i, k) in numberCount" :key="new Date().getTime() + Math.random() * 10 + '' + n + i"
                  colspan="1" rowspan="1">{{ code !== 'pcdd' ? i : k }}
                </th>
              </template>
            </tr>
          </thead>
          <tbody>
            <tr height="37" v-for="row in data" :key="new Date().getTime() + Math.random() * 10 + '' + row.expect">
              <td colspan="1" rowspan="1">{{ normal_time(row.opentime) }}
              </td>
              <td colspan="1" rowspan="1">{{ row.expect }}
              </td>
              <td v-if="is_rlt" colspan="1" rowspan="1">
                <div class="opencode">
                  <template v-for="(number, i) in row.opencode">
                    <number :value="number" :key="new Date().getTime() + Math.random() * 10 + '' + number"
                      :lottery-code="code" :lhc_icon="row.opencode.length === 7 && i == 6"></number>
                  </template>
                </div>
              </td>
              <template v-for="(number, rank) in row.list[indexForSelectRank]">
                <td colspan="1" rowspan="1" :key="rank">
                  <span v-if="number === row.opencode[indexForSelectRank]" class="hot"
                    :ref="`rank${indexForSelectRank}`">
                    <number v-if="code != 'dongganpuke'" :value="number" :lottery-code="code"></number>
                    <span v-else class="hot_qiu">{{ number }}</span>
                  </span>
                  <span v-else>{{ number }}</span>
                </td>
              </template>
            </tr>
          </tbody>
        </table>
        <trend-line-canvas v-for="obj in lineObjects" :key="obj.id" :width="obj.width" :height="obj.height"
          :start="obj.start" :end="obj.end" :color="obj.color" :line-width="obj.lineWidth" :top="obj.top"
          :left="obj.left"></trend-line-canvas>
      </div>
    </div>
  </div>
</template>

<script>
import { Loading, Select, Option } from 'element-ui'
import TrendLineCanvas from '@components/pc/TrendLineCanvas'
import Number from '@components/pc/Number'
import moment from 'moment'

export default {
  name: 'LotteryTrendNumber',
  props: {
    code: String
  },
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [TrendLineCanvas.name]: TrendLineCanvas,
    [Number.name]: Number
  },
  data() {
    return {
      per_page: 10,
      is_rlt: true,
      expectWidth: 142,
      opencodeWidth: 360,
      codeHeight: 37,
      data: [],
      loading: null,
      canvases: [],
      options: [
        {
          label: '40期',
          value: 40
        },
        {
          label: '90期',
          value: 90
        },
        {
          label: '今日所有',
          value: null
        }
      ],
      page: null,
      lineObjects: [],
      indexForSelectRank: 0,
      type_list: [],
      th_w: 23
    }
  },
  computed: {
    rankNames() {
      if (this.data.length === 0) return []
      const arr = []
      arr.push(this.type_list[this.indexForSelectRank])
      return arr
    },
    tableWidth() {
      const length = this.rankNames.length
      return this.expectWidth + this.opencodeWidth + this.codeWidth * length * this.numberCount
    },
    tableHeight() {
      return this.codeHeight * (2 + this.data.length)
    },
    numberCount() {
      if (this.data.length === 0) return 0
      return this.data[0].list[0].length
    }
  },
  watch: {
    code: function (val, oldVal) {
      this.per_page = 10
      this.requestGetHistory()
    },
    page: function (val, oldVal) {
      this.requestGetHistory()
    }
  },
  mounted() {
    this.requestGetHistory()
  },
  methods: {
    getKjW() {
      const code = this.code
      if (code === 'xyft' || code === 'donggansaiche' || code === 'jisupk10' || code === 'azxy10' || code === 'sbspeedy10' || code === 'sgairship') {
        return 140
      } else if (code === 'gdklsf' || code === 'azxy8' || code === 'jisukl10') {
        return 150
      } else if (code === 'donggan61' || code === 'hk6' || code === 'amlhc' || code === 'twlhc' || code === 'happy61') {
        return 140
      } else if (code === 'dongganpuke') {
        return 140
      }
      return 120
    },
    getQsW() {
      const code = this.code
      if (code === 'xyft' || code === 'donggansaiche' || code === 'jisupk10' || code === 'azxy10' || code === 'sbspeedy10' || code === 'sgairship') {
        return 100
      } else if (code === 'gdklsf' || code === 'azxy8' || code === 'jisukl10') {
        return 90
      } else if (code === 'donggan61' || code === 'hk6' || code === 'amlhc' || code === 'twlhc' || code === 'happy61') {
        return 75
      } else if (code === 'dongganpuke') {
        return 80
      }
      return 80
    },
    getOpenNumberW() {
      const code = this.code
      if (code === 'xyft' || code === 'donggansaiche' || code === 'jisupk10' || code === 'azxy10' || code === 'sbspeedy10' || code === 'sgairship') {
        return 330
      } else if (code === 'gdklsf' || code === 'azxy8' || code === 'jisukl10') {
        return 280
      }  else if (code === 'donggan61' || code === 'hk6' || code === 'amlhc' || code === 'twlhc' || code === 'happy61') {
        return 255
      }

      return 160
    },
    normal_time(date) {
      return moment(parseInt(date) * 1000).format('YYYY-MM-DD HH:mm:ss')
    },
    page_change(value) {
      this.per_page = value
      this.requestGetHistory()
    },
    type_fn(i) {
      this.indexForSelectRank = i
      this.is_rlt ? this.$refs.table.scrollLeft = 0 : ''
      this.$nextTick(() => {
        this.drawLine()
      })
    },
    get_list() {
      this.indexForSelectRank = 0
      this.type_list = this.get_type_name(this.code)
      const len = this.type_list.length
      this.th_w = len === 7 ? 23 : (len === 8 ? 36 : 60)
    },
    rlt_show() {
      this.is_rlt = !this.is_rlt
      this.$nextTick(() => {
        this.drawLine()
      })
    },
    refresh() {
      this.requestGetHistory()
    },
    hideTableHeader({ row, column, rowIndex, columnIndex }) {
      return {}
    },
    tableCellStyle({ row, column, rowIndex, columnIndex }) {
      return {}
    },
    requestGetHistory() {
      this.showTableLoading()
      this.data = []
      this.$api.numberTrend(this.code, this.per_page).then((res) => {
        this.get_list()
        this.data = [].concat(res.data)
        this.hideTableLoading()
        this.$nextTick(() => {
          this.drawLine()
        })
      }).catch((err) => {
        this.hideTableLoading()
        this.$message.error(err)
      })
    },
    showTableLoading() {
      if (this.loading) return
      this.loading = Loading.service({
        target: '#table',
        fullscreen: false,
        background: 'rgba(0, 0, 0, 0)'
      })
    },
    hideTableLoading() {
      this.$nextTick(() => {
        this.loading.close()
        this.loading = null
      })
    },
    drawLine() {
      this.lineObjects = []

      const lineWidth = 1

      const tableRect = this.$refs.table.getBoundingClientRect()

      const hots = this.getHotElement()
      for (var rank = 0; rank < hots.length; rank++) {
        const group = hots[rank]
        if (typeof group === 'object') {
          var lastCenter = null

          for (let index = 0; index < group.length; index++) {
            const hot = group[index]
            const hotRect = hot.getBoundingClientRect()
            const x = hotRect.x - tableRect.x + hotRect.width / 2
            const y = hotRect.y - tableRect.y + hotRect.height / 2
            const center = {
              x: x,
              y: y
            }
            if (!lastCenter) {
              lastCenter = center
              continue
            }

            var obj = {
              lineWidth,
              color: '#1D8DDE',
              id: `${rank}-${index - 1}-${index}`
            }

            const canvasWidth = Math.abs(lastCenter.x - x)
            const canvasHeight = Math.abs(lastCenter.y - y)

            obj.width = canvasWidth
            obj.height = canvasHeight

            if (lastCenter.x > x) {
              obj.start = {
                x: canvasWidth,
                y: 0
              }
              obj.end = {
                x: 0,
                y: canvasHeight
              }

              obj.left = x
              obj.top = lastCenter.y
            } else if (lastCenter.x < x) {
              obj.start = {
                x: 0,
                y: 0
              }
              obj.end = {
                x: canvasWidth,
                y: canvasHeight
              }

              obj.left = lastCenter.x
              obj.top = lastCenter.y
            } else {
              obj.start = {
                x: lineWidth / 2,
                y: 0
              }
              obj.end = {
                x: lineWidth / 2,
                y: canvasHeight
              }

              obj.left = (lastCenter.x - lineWidth / 2)
              obj.top = lastCenter.y
              obj.width = lineWidth
            }

            this.lineObjects.push(obj)
            lastCenter = center
          }
        }
      }
    },
    getHotElement() {
      var arr = []
      arr[this.indexForSelectRank] = document.getElementsByClassName('hot')
      return arr
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@scss/pc/variables";

.lottery-trend-number {
  >.header {
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    border-bottom: thin $color-border solid;

    >.title-box {
      display: flex;
      align-items: center;
      height: 49px;

      >.title {
        font-size: 20px;
        font-weight: 400;
        color: #222;
      }
    }

    >.search-box {
      display: flex;
      align-items: center;

      >.title {
        margin-right: 15px;
        font-size: 14px;
        color: #222;
      }

      >.icon-search {
        margin-left: 10px;
        width: 48px;
        height: 24px;
        display: block;
      }
    }
  }

  .lz_cont {
    padding: 16px;
    padding-top: 30px;
    padding-bottom: 0;
    box-sizing: border-box;
  }

  .switch {
    list-style: none;
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    margin-bottom: 26px;

    >li {
      margin-right: 10px;
      cursor: pointer;
      list-style: none;
      padding: 0 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: #222;
      height: 26px;
      border-radius: 50px;

      &.active {
        color: #FA3E3E;
        background-color: #FEEBEB;
      }
    }
  }

  .table {
    position: relative;
    // overflow-x: auto;
    overflow: auto;

    .table-data {
      table-layout: fixed;
      word-break: break-all;
      word-wrap: break-word;
      border-collapse: separate;
      border: 1px solid #d6d6d6;
      border-right: none;
      border-bottom: none;

      .el-table thead {
        color: #909399;
        font-weight: bold;
      }

      td,
      th {
        min-width: 0;
        box-sizing: border-box;
        text-overflow: ellipsis;
        vertical-align: middle;
        position: relative;
        text-align: center;
        border-bottom: 1px solid #d6d6d6;
        border-right: 1px solid #d6d6d6;
        font-size: 14px;
      }

      th {
        height: 37px;
        font-weight: bold;
        background-color: #F9F9F9;
        color: #B1B1B1;
      }

      td {
        color: #222;
      }
    }

    .opencode {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fa3e3e;

      span+span {
        margin-left: 5px;
      }
    }

    .hot {
      position: relative;
      z-index: 100;

      .hot_qiu {
        display: inline-block;
        color: white;
        background: #07A6F8;
        width: 24px;
        height: 24px;
        line-height: 24px;
        font-size: 14px;
        text-align: center;
        border-radius: 50%;
      }
    }
  }
}

.sum-tip {
  padding: 12px;

  >.title {
    font-size: 14px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.8);
  }

  >.content {
    margin-top: 8px;
    line-height: 20px;
    font-size: 12px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
  }
}

.switch_fn_bt {
  margin-right: 20px;
  width: 80px;
  height: 24px;
  border-radius: 4px;
  line-height: 24px;
  font-size: 14px;
  text-align: center;
  background-color: #F9F9F9;
  color: #222;
  border: 1px solid #E3E3E6;
  cursor: pointer;
}
</style>

<style lang="scss">
.hot {
  .lottery-number {
    width: 24px !important;
    height: 24px !important;
    line-height: 24px !important;
  }
}
</style>
